// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-leap-tsx": () => import("./../../../src/pages/all-leap.tsx" /* webpackChunkName: "component---src-pages-all-leap-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leaps-leap-markdown-remark-frontmatter-leap-tsx": () => import("./../../../src/pages/leaps/leap-{MarkdownRemark.frontmatter__leap}.tsx" /* webpackChunkName: "component---src-pages-leaps-leap-markdown-remark-frontmatter-leap-tsx" */)
}

